  'use strict'
  
  resizeFlg = {}
  resizeFlg.pc = true
  resizeFlg.sp = true
  firstView = ''
  
  spMenu = ()->
    console.log('menu:SP')
  
    showSubMenu = ()->
      $('.js-menu').removeClass('is-active')
  
      if $(this).hasClass('is-active')
        $('.js-menu__button').removeClass('is-active')
        $(this).removeClass('is-active')
  
        currentPos = Math.abs(parseInt($('html').css('top').replace('px')) * -1)
  
        $('.js-menu').on('transitionend', (e)->
          $('html').removeAttr('style').removeClass('is-fixed')
  
          $(
            if (navigator.userAgent.indexOf('Opera') isnt -1)
            then (
              if document.compatMode is 'BackCompat'
              then 'body'
              else 'html'
            )
            else 'html, body'
          ).animate({
            scrollTop: currentPos
          }, 0)
        )
      else
        $('.js-menu__button').removeClass('is-active')
        $(this).addClass('is-active')
  
        $('.js-menu').addClass('is-active')
  
        if $('html').attr('style')
          currentPos = Math.abs(parseInt($('html').css('top').replace('px')) * -1)
        else
          currentPos = $(window).scrollTop()
  
        $('html').addClass('is-fixed')
        $('html').css('top', currentPos * -1)
  
      if $('.js-menu.is-active').length > 0
        $('.l-container, .p-header, .js-menu').on('click.menuclose', (e)->
          e.stopPropagation()
        )
  
        $('.l-container').on('click.menuclose', (e)->
          $('.js-menu__button').trigger('click')
        )
      else
        $('.l-container, .p-header, .js-menu').off('click.menuclose')
  
      $('.js-menu').on('transitionend', (e)->
        if $('.js-menu.is-active').length is 0
          $('html').removeClass('is-fixed')
          $(this).off('transitionend')
          $('.js-menu').removeClass('is-close')
        else
          $('.js-menu').addClass('is-close')
      )
  
    $('.js-menu__button').on('click', showSubMenu)
  
    $(window).on('scroll',
      ()->
        if $(this).scrollTop() >= $('.l-h1').height()
          $('.p-header').addClass('is-sticky')
        else
          if not $('html').hasClass('is-fixed')
            $('.p-header').removeClass('is-sticky')
    )
  
  pcMenu = ()->
    console.log('menu:PC')
  
  if window.matchMedia('(max-width:750px)').matches
    # SP
    firstView = 'SP'
  
    spMenu()
  else
    # PC
    firstView = 'PC'
  
    pcMenu()
  
  $(window).on('resize',
    ()->
      if window.matchMedia('(max-width:750px)').matches
        if firstView is 'PC' and resizeFlg.sp is true or
        firstView is false and resizeFlg.sp is true
        # PC から SP へ
  
          $('.js-menu').addClass('is-resize')
  
          spMenu()
  
          $('.js-menu').on('transitionend', (e)->
            if e.originalEvent.propertyName is 'transform' and e.originalEvent.target is $('.js-menu').get(0)
              $('.js-menu').removeClass('is-resize')
          )
  
          resizeFlg.pc = true
          resizeFlg.sp = false
          firstView = false
      else
        if firstView is 'SP' and resizeFlg.pc is true or
        firstView is false and resizeFlg.pc is true
        # SP から PC へ
  
          $('.js-menu__button').off()
  
          pcMenu()
  
          resizeFlg.pc = false
          resizeFlg.sp = true
          firstView = false
  )
  